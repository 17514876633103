import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { motion, AnimatePresence, useViewportScroll } from 'framer-motion';
import { useWheel } from 'react-use-gesture';
import Img from 'gatsby-image';

import { updateService, updateScrollbarScale, toggleNav, setFullscreen } from "./store";

import '../index.less';

const pages = [
	'/',
	'/about',
	'/services',
	'/realisations',
	'/clients',
	'/contact'
];

const pageNames = [
	'Accueil',
	'À propos',
	'Services',
	'Réalisations',
	'Clients',
	'Contact'
];

const bgImgs = [
	'bg-home.jpg',
	null,
	'bg-services.jpg',
	'bg-realisations.jpg',
	'bg-clients.jpg',
	'bg-contact.jpg'
];

const variants = {
	initial: {
		opacity: 0
	},
	enter: {
		opacity: 1,
		filter: 'blur(0px)'
	},
	blur: {
		filter: 'blur(5px)'
	},
	exit: {
		opacity: 0,
		filter: 'blur(5px)'
	}
};

const blurVariants = {
	initial: {
		filter: 'blur(0px)'
	},
	blur: {
		filter: 'blur(5px)'
	}
};

const navVariants = {
	initial: {
		x: '100%'
	},
	visible: {
		x: 0
	}
};

const Layout = ({ data, navOpen, fullscreen, realisations, dispatch, children, location }) => {
	const [ scrollDisabled, setScrollDisabled ] = useState(false);
	const { scrollYProgress } = useViewportScroll();
	const [ page, setPage ] = useState(0);

	const bind = useWheel(({ event, first, last }) => {
		if (first && !scrollDisabled && fullscreen === null) {
			/*if (location.pathname === '/realisations') {
				if (event.deltaY > 0) {
					let s = service + 1;

					if (s === 4)
						navigate(pages[pages.findIndex(i => i === '/realisations') + 1]);
					else
						dispatch(updateService(s));
				}
				else if (event.deltaY < 0) {
					let s = service - 1;

					if (s === -1)
						navigate(pages[pages.findIndex(i => i === '/realisations') - 1]);
					else
						dispatch(updateService(s));
				}
			}
			else {*/
				if ((event.deltaY > 0 && scrollYProgress.current === 1) || event.deltaY > 0) {
					let index = pages.findIndex(i => i === location.pathname);
					index += 1;

					/*if (index === 1)
						dispatch(updateService(0));*/

					if (index < pages.length) {
						dispatch(updateScrollbarScale(100));
						navigate(pages[index]);
					}
				}
				else if (event.deltaY < 0 && scrollYProgress.current === 0) {
					let index = pages.findIndex(i => i === location.pathname);
					index -= 1;

					/*if (index === 1)
						dispatch(updateService(3));*/

					if (index >= 0) {
						dispatch(updateScrollbarScale(100));
						navigate(pages[index]);
					}
				}
			//}
		}

		if (last)
			setTimeout(() => setScrollDisabled(false), 300);
	}, {
		domTarget: typeof document === 'undefined' ? null : document.getElementById('___gatsby')
	});

	useEffect(() => {
		setPage(pages.findIndex(i => i === location.pathname));
		bind();
	}, [setPage, location, bind]);

	const backgroundImg = `bg-${location.pathname === '/' ? 'home' : location.pathname.substring(1)}.jpg`;

	return (
		<div
			className="layout"
			onClick={() => dispatch(toggleNav(false))}
		>
			<AnimatePresence>
				{bgImgs.map((img, index) => (
					<>
						{index === page && (<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								backgroundImage: `url('${img}')`,
								backgroundPosition: 'center',
								backgroundSize: 'cover'
							}}
						/>)}
					</>
				))}
			</AnimatePresence>


			<motion.header
				variants={blurVariants}
				initial="initial"
				animate={navOpen ? 'blur' : 'initial'}
			>
				<img src="/logo.svg" alt="Concilio" />
				<div
					onClick={e => {
						e.stopPropagation();
						dispatch(toggleNav(true));
					}}>
					<span className="nav-icon" />
					MENU
				</div>
			</motion.header>


			<motion.div
				className="nav"
				variants={navVariants}
				initial="initial"
				animate={navOpen ? 'visible' : 'initial'}
				onClick={e => e.stopPropagation()}
			>
				<span className="close" onClick={() => dispatch(toggleNav(false))}>X</span>

				<ul className="menu">
					{pages.map((page, index) => (
						<li key={index}><a href={page}>{pageNames[index]}</a></li>
					))}
				</ul>

				<div className="languages">
					<a href="" className="active">FR</a> | <a href="">EN</a>
				</div>
			</motion.div>

			<motion.div
				className="nav-viewer"
				variants={blurVariants}
				initial="initial"
				animate={navOpen ? 'blur' : 'initial'}
			>
				<div className="nav-label">{pageNames[page]}</div>
				<div className="nav-bar" style={{ top: `${page * 16.666}%` }} />
			</motion.div>

			<AnimatePresence exitBeforeEnter>
				<motion.main
					key={location.pathname}
					variants={variants}
					initial="initial"
					animate={navOpen ? 'blur' : 'enter'}
					exit="exit"
					id="main"
				>
					{children}
				</motion.main>
			</AnimatePresence>

			{page < pages.length - 1 && (
				<div className="scroll-down" style={{ position: 'absolute', bottom: '15vh', left: '50%', transform: 'translateX(-50%)' }}>
					<small style={{ color: 'rgba(255, 255, 255, 0.5)' }}>scroll down</small>
					<div style={{ position: 'absolute', left: '50%', width: 1, height: 50, marginTop: 20, background: 'rgba(255, 255, 255, 0.3)' }} />
				</div>
			)}

			<motion.div
				initial={{
					opacity: 0,
					scale: 0,
					overflow: 'hidden'
				}}
				animate={{
					opacity: fullscreen === null ? 0 : 1,
					scale: fullscreen === null ? 0 : 1
				}}
				transition={{
					duration: 0.3
				}}
				className="fullscreen-realisations"
			>
				{fullscreen !== null && (
					<>
						<div className="image">
							<Img fluid={data[realisations[fullscreen].image].childImageSharp.fluid} />
						</div>
						<div className="text">
							<h3 style={{ fontSize: 32 }}>{realisations[fullscreen].title}</h3>
							{realisations[fullscreen].desc}
						</div>
					</>
				)}
				<div className="close" onClick={() => dispatch(setFullscreen(null))}>X</div>
			</motion.div>
		</div>
	);
};

export default connect(
	state => ({
		navOpen: state.navOpen,
		fullscreen: state.fullscreen,
		realisations: state.realisations
	})
)(Layout);

export const query = graphql`
	query {
		foobar: file(relativePath: { eq: "foobar.png" }) {
			childImageSharp {
				fluid(maxWidth: 764, maxHeight: 1056) {
					...GatsbyImageSharpFluid
				}
			}
		}
		bgHome: file(relativePath: { eq: "bg-home.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1920, maxHeight: 1280) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
