import { createStore } from 'redux';
import React from "react";

export const toggleLoading = loading => ({
	type: 'TOGGLE_LOADING',
	loading
});

export const toggleNightMode = () => ({
	type: 'TOGGLE_NIGHT_MODE'
});

export const updateService = service => ({
	type: 'UPDATE_SERVICE',
	service
});

export const updateScrollbarScale = scale => ({
	type: 'UPDATE_SCROLLBAR_SCALE',
	scale
});

export const toggleNav = visible => ({
	type: 'TOGGLE_NAV',
	visible
});

export const setFullscreen = index => ({
	type: 'SET_FULLSCREEN',
	index
});

const defaultState = {
	loading: true,
	nightMode: false,
	service: 0,
	scrollbarScale: 100,
	navOpen: false,
	fullscreen: null,
	realisations: [
		{
			title: 'Fondation ArtTech – dès 2017',
			image: 'foobar',
			desc: <>
				<p>Nathalie Pichard a co-fondé et dirige depuis 2017 la Fondation ArtTech qui soutient la création de projets avant-gardistes qui englobent toutes les formes d'art, préservent et promeuvent le patrimoine culturel grâce à l'utilisation des nouvelles technologies. La Fondation promeut également les start-ups actives dans le domaine.</p>
				<p>Concilio intervient pour :</p>
				<ul>
					<li>gestion opérationnelle la Fondation</li>
					<li>stratégie de développement</li>
					<li>curation et organisation du Forum international ArtTech et du Prix ArtTech destiné aux start-ups (en Suisse et à l’étranger)</li>
					<li>recherche et suivi des start-ups actives dans le domaine</li>
					<li>représentation du Conseil de la Fondation</li>
					<li>Développement d’un réseau culture et innovation technologique</li>
				</ul>
			</>
		},
		{
			title: 'Université de Lausanne – Service Culture, Science, Société – 2018 - 2019',
			image: 'foobar',
			desc: <>
				<p>Nathalie Pichard contribue à la mise en place de la politique culturelle et de médiation scientifique de l’Université de Lausanne. Elle assure différentes prestations opérationnelles :</p>
				<ul>
					<li>développement de la stratégie</li>
					<li>audit organisationnel</li>
					<li>management ad interim d’une unité administrative</li>
					<li>conseil et accompagnement de la mise en place d’un nouveau service</li>
					<li>coordination de projets et d’événements</li>
				</ul>
			</>
		},
		{
			title: 'Etat de Vaud – Plateforme 10 – 2018 -2019',
			image: 'foobar',
			desc: <>
				<p>Le projet Plateforme 10 réunit trois musées du Canton de Vaud et de la Ville de Lausanne sur un nouveau site. Le projet comporte plusieurs enjeux organisationnels Plateforme 10 a fait appel à Concilio pour :</p>
				<ul>
					<li>cartographie des prestations réalisées par chacun des musées pouvant être mutualisés</li>
					<li>change management</li>
				</ul>
			</>
		},
		{
			title: 'Association Paul Collart au Proche-Orient – Institut d’archéologie et des sciences de l’Antiquité de l’Université de Lausanne',
			image: 'foobar',
			desc: <>
				<p>En collaboration étroite avec l’Institut d’archéologie et des sciences de l’Antiquité de l’Université de Lausanne qui assure le suivi scientifique, l’Association Paul Collart au Proche-Orient soutient le projet Collart-Palmyre. Concilio est mandatée pour :</p>
				<ul>
					<li>conseil stratégique et coordination du projet avec la Direction de l’UNIL</li>
					<li>mise en place d’un outil de suivi financier</li>
					<li>accompagnement du responsable scientifique</li>
					<li>co-curation de l’exposition organisée à ArtLab EPFL en automne 2020</li>
				</ul>
			</>
		},
		{
			title: 'Collectivités publiques 2019-2020',
			image: 'foobar',
			desc: <>
				<p>Concilio intervient principalement auprès d’institutions publiques confrontées à la mise en place de projets complexes nécessitant le recours à un appui ponctuel externe. Les mandats remplis portent sur :</p>
				<ul>
					<li>analyses et état de situation</li>
					<li>conseil et accompagnement auprès de la personne en charge du projet</li>
					<li>rédaction de documents et présentations</li>
					<li>coordination avec les parties prenantes</li>
				</ul>
			</>
		},
		{
			title: 'Réalisation de projets culturels 2017-2018',
			image: 'foobar',
			desc: <>
				<p>Concilio prend en charge tout projet culturel, de la conception à la réalisation, particulièrement s’il intègre une dimension innovante. Concilio a réalisé sur support numérique la présentation des œuvres d’un artiste (arts visuels) :</p>
				<ul>
					<li>conduite du projet</li>
					<li>direction artistique</li>
					<li>mandats et suivi auprès des prestataires partenaires du projet</li>
				</ul>
			</>
		},
		{
			title: 'Consulting – Hautes Ecoles dès 2017',
			image: 'foobar',
			desc: <>
				<p>Concilio collabore avec l’entreprise Berinfor AG spécialisée dans le soutien aux hautes écoles. Divers mandats ont été réalisés en Suisse romande portant sur :</p>
				<ul>
					<li>analyses et études organisationnelles (interviews, rédaction de rapports, mise en place d’outils de suivi)</li>
					<li>présentation de solutions</li>
				</ul>
			</>
		},
		{
			title: 'Fondations d’utilité publique',
			image: 'foobar',
			desc: <>
				<p>Nathalie Pichard s’engage au sein de fondations d’utilité publique œuvrant dans le domaine culturel, social ou de la promotion des sciences. Elle a occupé ou occupe encore les fonctions suivantes :</p>
				<ul>
					<li>Présidente de la Fondation pour les Etudiants de l’EPFL</li>
					<li>Présidente de la Fondation Espaces des inventions</li>
					<li>Vice-présidente de la Fondation Science et Jeunesse</li>
					<li>Membres de la Fondation Maisons pour Etudiants Lausanne</li>
					<li>Membre de la Fondation du Musée suisse de l'appareil photographique de Vevey</li>
					<li>Membre de la Fondation Erna Hamburger</li>
					<li>Membre de la commission culturelle de la Fondation Leenaards</li>
				</ul>
			</>
		}
	]
};

const reducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'TOGGLE_LOADING':
			return Object.assign({}, state, { loading: action.loading });

		case 'TOGGLE_NIGHT_MODE':
			return Object.assign({}, state, { nightMode: !state.nightMode });

		case 'UPDATE_SERVICE':
			return Object.assign({}, state, { service: action.service });

		case 'UPDATE_SCROLLBAR_SCALE':
			return Object.assign({}, state, { scrollbarScale: action.scale });

		case 'TOGGLE_NAV':
			return Object.assign({}, state, { navOpen: action.visible });

		case 'SET_FULLSCREEN':
			return Object.assign({}, state, { fullscreen: action.index });

		default:
			return state;
	}
};

export default createStore(reducer);
